import "lazysizes"
import "./bundle/jquery.swipebox"

import {
	debounce,
	normalizeVH,
	//smoothScroll,
} from "./bundle/rcobiella-helpers.js"

//import Cookies from "js-cookie"
//import Scrollbar from "smooth-scrollbar";
import sal from "sal.js"
//import smoothscroll from "smoothscroll-polyfill";

// kick off the polyfill!
//smoothscroll.polyfill();

normalizeVH()

console.log('test')

const salOptions = {
	threshold: 0.5,
}

/* -------------------------------------------------------------------------- */
/*                              LazySizes config                              */
/* -------------------------------------------------------------------------- */

window.lazySizesConfig = {
	loadMode: 1,
	preloadAfterLoad: true,
}
lazySizesConfig.preloadAfterLoad = true

//add simple support for background images:
document.addEventListener( "lazybeforeunveil", function ( e ) {
	var bg = e.target.getAttribute( "data-bg" )
	if ( bg ) {
		e.target.style.backgroundImage = "url(" + bg + ")"
	}
} )

/* -------------------------------------------------------------------------- */
/*                              isMobile & TOUCH                              */
/* -------------------------------------------------------------------------- */

const TOUCH = "ontouchstart" in window

TOUCH &&
window.innerWidth < 768 &&
document.querySelectorAll( ".desktop-tablet-only" ).forEach( function ( a ) {
	a.remove()
} )

let isMobile = window.innerWidth <= 1194

const checkMobile = () => {
	isMobile = window.innerWidth <= 1194
}
window.addEventListener( "resize", debounce( checkMobile, 20 ) )

jQuery( function ( $ ) {
	/* -------------------------------------------------------------------------- */
	/*                            Horizontal Scrollbar                            */
	/* -------------------------------------------------------------------------- */

	// const $featuresGrid = $(".features-grid");
	// let scrollOptions = {
	// 	alwaysShowTracks: true,
	// 	renderByPixels: true,
	// };
	// if ($featuresGrid.length && TOUCH) {
	// 	Scrollbar.init($featuresGrid.get(0), scrollOptions);
	// }

	/* -------------------------------------------------------------------------- */
	/*              Hide elastic scroll showing images in split page              */
	/* -------------------------------------------------------------------------- */

	$( window ).on( "load resize scroll", debounce( checkSplitPageBackground, 10 ) )

	function checkSplitPageBackground() {
		$( "html" ).toggleClass( "scroll-is-top", window.scrollY <= 0 )
	}

	/* -------------------------------------------------------------------------- */
	/*                           Login form placeholders                          */
	/* -------------------------------------------------------------------------- */

	const $loginForm = $( "#loginform" )
	if ( $loginForm.length ) {
		$( "input#user_login" ).attr( "placeholder", "Username" )
		$( "input#user_pass" ).attr( "placeholder", "Password" )
	}

	/* -------------------------------------------------------------------------- */
	/*                          Sticky footer menu mobile                         */
	/* -------------------------------------------------------------------------- */
	$( "body" ).on( "click", ".sticky-sub-nav-trigger", function () {
		//let $dis = $(this);
		$menuCheckbox.trigger( "click" )

		let scrollPos = window.scrollY
		setTimeout( function () {
			$( "body, html" ).scrollTop( 0 )
		}, 400 )

		$menuCheckbox.one( "change", function () {
			if ( !$menuCheckbox.prop( "checked" ) ) {
				if ( scrollPos ) {
					$( "body, html" ).scrollTop( scrollPos )
				}
			}
		} )
	} )

	/* -------------------------------------------------------------------------- */
	/*                             Video controls show                            */
	/* -------------------------------------------------------------------------- */
	$( "body" ).on( "click", "video.has-controls", function () {
		let $dis = $( this )
		$dis.attr( "controls", "true" ).removeClass( "has-controls" )
		$dis[0].play()
	} )

	/* -------------------------------------------------------------------------- */
	/*                                Header height                               */
	/* -------------------------------------------------------------------------- */

	const headerHeight = () => {
		const setHeaderHeight = () => {
			console.log("setting header height", $( "header.header" ).outerHeight() + "px")
			document.documentElement.style.setProperty(
				"--full-header",
				$( "header.header" ).outerHeight() + "px"
			)
		}

		window.addEventListener( "resize", function () {
			console.log("set header height trigger", "resize")
			setHeaderHeight()
		} )
		window.addEventListener( "orientationchange", function () {
			console.log("set header height trigger", "orientationchange")
			setHeaderHeight() 
		} )
		window.addEventListener( "DOMContentLoaded", () => {
			console.log("set header height trigger", "DOMContentLoaded")
			setHeaderHeight()
		} )
		jQuery(document).ready(function() {
			console.log("set header height trigger", "doc ready")
			setHeaderHeight()
		})
	}

	headerHeight()

	/* -------------------------------------------------------------------------- */
	/*                               Hero scroll to                               */
	/* -------------------------------------------------------------------------- */
	$( ".hero-scroll-down" ).on( "click", function () {
		window.scrollTo( {
			top: window.innerHeight,
			left: 0,
			behavior: "smooth",
		} )
	} )

	$( ".footer-sticky-logo" ).on( "click", function () {
		window.scrollTo( {
			top: 0,
			left: 0,
			behavior: "smooth",
		} )
	} )

	/* -------------------------------------------------------------------------- */
	/*                       Add body class if Hero is found                      */
	/* -------------------------------------------------------------------------- */
	if ( $( ".hero.hero-normal" ).length ) {
		$( "body" ).addClass( "has-hero" )
	}

	/* -------------------------------------------------------------------------- */
	/*                                   Gallery                                  */
	/* -------------------------------------------------------------------------- */
	const $galSelect = $( ".gallery-select-dropdown" ),
		$galType = $( ".gallery-select-dropdown.type" ),
		$galProjects = $( ".gallery-select-dropdown.projects" ),
		$galProducts = $( ".gallery-select-dropdown.products" )

	function galleryUpdateSelection() {
		let type = $galType.val(),
			projects = $galProjects.val(),
			products = $galProducts.val()

		/* ---------------------------------- Type ---------------------------------- */
		if ( type == "projects" ) {
			// Hide products select, show projects
			$( ".gallery-select-products" ).hide()
			$( ".gallery-select-projects" ).show()
			// Hide all items
			$( ".gallery-item" ).hide()
			// Show selected project
			if ( projects == "all" ) {
				$( ".gallery-item.type-projects" ).show()
			} else {
				$( ".gallery-item.type-projects.id-" + projects ).show()
			}
		} else if ( type == "products" ) {
			// Hide projects select, show projects
			$( ".gallery-select-products" ).show()
			$( ".gallery-select-projects" ).hide()
			// Hide all items
			$( ".gallery-item" ).hide()
			// Show selected project
			if ( products == "all" ) {
				$( ".gallery-item.type-products" ).show()
			} else {
				$( ".gallery-item.type-products.id-" + products ).show()
			}
		}
	}

	if ( $galSelect.length ) {
		galleryUpdateSelection()
		$galSelect.on( "change", galleryUpdateSelection )
	}
	/* -------------------------------------------------------------------------- */
	/*                                  Instagram                                 */
	/* -------------------------------------------------------------------------- */
	//$(window).on("load", function () {
	if ( $( ".page-template-template-instagram" ).length ) {
		var instaFeed
		if ( $( "#instafeed" ).length ) {
			// create two separate instances of Instafeed
			instaFeed = new Instafeed( {
				get: "user",
				userId: ig_userid,
				accessToken: ig_token,
				limit: ig_limit,
				sortBy: ig_sortby,
				resolution: "standard_resolution",
				template:
					"<a class=\"instafeed-item flex-grid-item\" target=\"_blank\" data-sal=\"slide-up\" href=\"{{link}}\" title=\"{{caption}}\"><div class=\"instafeed-image\" style=\"--aspect-ratio: 1\"><img class=\"lazyload\" data-src=\"{{model.images.standard_resolution.url}}\"/></div></a>",
			} )
			instaFeed.run()
			$( ".instagram-load-more" ).on( "click", function () {
				// $(".blog-instagram, .block-newsletter")
				// 	.removeClass("aos-init aos-animate")
				// 	.removeAttr("data-aos");
				instaFeed.next()
			} )
		}
	}
	//});

	/* -------------------------------------------------------------------------- */
	/*                        Distributors: Country select                        */
	/* -------------------------------------------------------------------------- */

	const $distrSelect = $( "#distributors-select" )
	if ( $distrSelect.length ) {
		$distrSelect.on( "change", function ( e ) {
			let $dis = $( this )
			let value = $dis.val()

			if ( value == "all" ) {
				$( ".distributor-item" ).show()
			} else {
				$( ".distributor-item" ).hide()
				$( ".distributor-item.country-" + value ).show()
			}
		} )
	}

	/* -------------------------------------------------------------------------- */
	/*                           Product options - Popup                          */
	/* -------------------------------------------------------------------------- */

	$( "body" ).on( "click", ".product-section-item", function () {
		const $dis = $( this ),
			$pPopup = $( ".product-options-popup" )

		$pPopup.hide()
		let name = $dis.attr( "data-name" ),
			img = $dis.attr( "data-img" ),
			code = $dis.attr( "data-code" ),
			color = $dis.attr( "data-color" ),
			content = $dis.attr( "data-content" ),
			collection = $dis.attr( "data-collection" )

		let $name = $( ".product-options-popup" ).find( ".product-options-name" ),
			$collection = $( ".product-options-popup" ).find(
				".product-options-collection"
			),
			$img = $( ".product-options-popup" ).find( ".product-options-img" ),
			$code = $( ".product-options-popup" ).find( ".product-options-code" ),
			$color = $( ".product-options-popup" ).find( ".product-options-color" ),
			$content = $( ".product-options-popup" ).find(
				".product-options-content"
			)
		setTimeout( function () {
			$name.html( name ?? "" )
			$img.attr( "src", img )
			$code.html( code ?? "" )
			$color.html( color ?? "" )
			$collection.html( collection ?? "" )
			$content.html( content ?? "" )

			setTimeout( function () {
				// Open popup
				$pPopup.css( "display", "flex" ).hide().fadeIn()

				// Close on action
				$( window ).one( "click scroll", function () {
					$pPopup.fadeOut( function () {
					} )
				} )
			}, 250 )
		}, 100 )
	} )

	/* -------------------------------------------------------------------------- */
	/*                                 Share links                                */
	/* -------------------------------------------------------------------------- */

	$( ".share-trigger" ).on( "click", function () {
		let $dis = $( this ),
			$parent = $dis.parent( ".share-container" )
		if ( $parent.length ) {
			$parent.toggleClass( "is-active" )
		}
	} )
	/* -------------------------------------------------------------------------- */
	/*                          Enquire / Popup container                         */
	/* -------------------------------------------------------------------------- */

	$( ".popup-container" ).each( function () {
		const $dis = $( this )
		const $close = $dis.find( ".popup-close" )

		// Close popup
		if ( $close.length ) {
			$close.on( "click", function () {
				$dis.removeClass( "is-open" )
				if ( $dis.hasClass( "enquire-popup" ) ) {
					setTimeout( function () {
						$( "body, html" ).removeClass( "enquire-is-opened" )
					}, 200 )
				}
				setTimeout( function () {
					$( "body, html" ).removeClass( "popup-is-opened" )
				}, 250 )
			} )
		}
	} )

	// Enquire
	const $enquireTrigger = $( "a[href='#enquire']" )
	const $enquireContainer = $( ".popup-container.enquire-popup" )

	$enquireTrigger.on( "click", function ( e ) {
		e.preventDefault()
		$enquireContainer.addClass( "is-open" )
		$( "body, html" ).addClass( "popup-is-opened enquire-is-opened" )
	} )

	/* -------------------------------------------------------------------------- */
	/*                              Newsletter popup                              */
	/* -------------------------------------------------------------------------- */

	//let newsletterCookie = Cookies.get("newsletter_popup");
	let newsletterCookie = false
	const $newsletterPopup = $( ".newsletter-popup" )

	if (
		typeof newsletterPopup !== undefined &&
		!newsletterCookie &&
		$newsletterPopup.length
	) {
		setTimeout( function () {
			$newsletterPopup.fadeIn()
			//Cookies.set( "newsletter_popup", "1", {
			//	expires: parseInt( newsletterPopup.every ),
			//} )
		}, newsletterPopup.delay )

		$newsletterPopup.find( ".popup-close" ).on( "click", function () {
			$newsletterPopup.fadeOut()
		} )
	}

	//Cookies.set("newsletter_popup", "1", { expires: 7 });

	/* -------------------------------------------------------------------------- */
	/*                               Form submission                              */
	/* -------------------------------------------------------------------------- */
	/* -------------------------------------------------------------------------- */
	/*                                ACF FORM AJAX                               */
	/* -------------------------------------------------------------------------- */

	if ( typeof acf != "undefined" ) {
		acf.do_action( "ready", $( "body" ) )

		let isValidating = false

		acf.add_action( "validation_begin", () => {
			isValidating = true
		} )

		acf.add_action( "submit", ( $form ) => {
			isValidating = false
		} )

		const $contactForm = $( "form.acfe-form" )

		let isSubmitted = false

		$contactForm.on( "submit", ( e ) => {
			var $form = $( e.target )
			var $contactMsg = $form.siblings( ".form-response" )
			var $contactSubmit = $form.find( ".button-submit-container" )
			e.preventDefault()
			e.stopPropagation()
			if ( !isValidating && !isSubmitted ) {
				isSubmitted = true
				$form.addClass( "is-locked" )
				$.ajax( {
					url: window.location.href,
					method: "post",
					data: $form.serialize(),
					success: ( data ) => {
						$contactMsg
							.html(
								"<i class='fal fa-check-circle'></i> Your enquiry has been submitted succesfully."
							)
							.addClass( "is-active success" )
						setTimeout( function () {
							$contactMsg.removeClass( "is-active success" )
							$form[0].reset()
							$form.removeClass( "is-locked" )
						}, 3000 )
						isSubmitted = false
					},
					error: ( data ) => {
						console.log( "ERROR", data )
						$contactMsg
							.html(
								"<i class='fal fa-exclamation-circle'></i> Please verify you've filled all the required fields."
							)
							.addClass( "is-active error" )
						setTimeout( function () {
							$contactMsg.removeClass( "is-active error" )
							$form.removeClass( "is-locked" )
						}, 3000 )
						isSubmitted = false
					},
				} )
			}
		} )
	}

	/* -------------------------------------------------------------------------- */
	/*                                  Lightbox                                  */

	/* -------------------------------------------------------------------------- */

	function bindKeyGallery() {
	}

	function unbindKeyGallery() {
	}

	$( ".gallery-item" ).swipebox( { 
		useCSS: true,
		useSVG: true,  
		initialIndexOnArray: 0,
		hideCloseButtonOnMobile: false,
		removeBarsOnMobile: false,
		hideBarsDelay: 0,
		beforeOpen: function () {
		},
		afterOpen: function () {
			$( "#swipebox-overlay" ).addClass( "opened" )
			$( "html" ).addClass( "no-scroll" )
			$( "#swipebox-close" ).on( "click", function () {
				$( "html" ).removeClass( "no-scroll" )
			} )
		},
		afterClose: function () {
			$( "#swipebox-close" ).off( "click" )
			$( "html" ).removeClass( "no-scroll" )
		},
		loopAtEnd: false,
	} )

	// Next slide on image click
	$( "body" ).on( "click", "#swipebox-slider .slide.current", function () {
		//$( "a#swipebox-next" ).trigger( "click" )
	} )

	/* -------------------------------------------------------------------------- */
	/*                            Menu overlay trigger                            */
	/* -------------------------------------------------------------------------- */
	//const $menuTrigger = $( ".menu-trigger" )
	const $menuOverlay = $( ".menu-overlay" )
	const $menuCheckbox = $( "#menu-overlay-toggle" )

	$menuCheckbox.on( "change", menuOverlayToggle )

	function menuOverlayToggle() {
		console.log("menu triggered")
		//$("body").toggleClass("menu-overlay-active");
		if ( $menuCheckbox.prop( "checked" ) ) {
			// Open
			$( "body" ).addClass( "menu-overlay-active" )
			$menuOverlay
				.css( "display", "flex" )
				.hide()
				.fadeIn()
				.addClass( "is-active" )
			$( "html" ).addClass( "no-overflow" )

			// Fix menu hovers not working on second menu open
			if ( !TOUCH ) {
				$menuOverlay.find( "ul.sub-menu" ).show()
			}
			// else {
			// 	$menuOverlay.find(".submenu-wrap").show();
			// }
		} else {
			// Close
			$( "body" ).removeClass( "menu-overlay-active" )
			$menuOverlay.fadeOut().removeClass( "is-active" )
			setTimeout( function () {
				if ( !TOUCH ) {
					$menuOverlay.find( "ul.sub-menu" ).hide()
				} else {
					$menuOverlay.find( ".submenu-wrap" ).hide()
				}
			}, 600 )
			$( "html" ).removeClass( "no-overflow" )
		}
	}

	// Menu mobile dropdowns
	if ( TOUCH ) {
		$( ".menu-overlay-container > ul > li.menu-item-has-children" ).each(
			function () {
				let $menu = $( this )
				let $link = $menu.find( "> a" )
				let $submenu = $menu.find( "ul.sub-menu" )
				$submenu.wrap( "<div class=\"submenu-wrap\"></div>" )
				let $submenuwrap = $menu.find( ".submenu-wrap" )
				$link.on( "click", function ( e ) {
					e.preventDefault()
					$menu
						.siblings( ".menu-item-has-children" )
						.find( "> .submenu-wrap" )
						.slideUp( {
							duration: 250,
						} )
					$submenuwrap.slideToggle( {
						duration: 250,
						// start: function () {
						// 	$(this).css("display", "flex");
						// },
					} )
				} )
			}
		)
	}

	/* -------------------------------------------------------------------------- */
	/*                                FIXED SUB NAV                               */
	/* -------------------------------------------------------------------------- */
	const $navSub = $( "#nav-sub" )

	if ( !!window.IntersectionObserver ) {
		let observer = new IntersectionObserver(
			( entries, observer ) => {
				entries.forEach( ( entry ) => {
					if ( entry.isIntersecting ) {
						if ( $( "#sticky-sub-nav" ).length ) {
							$( "#sticky-sub-nav" ).removeClass( "is-visible" )
						}
					} else {
						if ( $( "#sticky-sub-nav" ).length ) {
							$( "#sticky-sub-nav" ).addClass( "is-visible" )
						}
					}
				} )
			},
			//{ rootMargin: "0px 0px -200px 0px" }
			{ rootMargin: "0px 0px 0px 0px" }
		)
		if ( $navSub.length ) {
			const $navSubMenu = $navSub.find( "> ul" )

			$navSub
				.clone()
				.prependTo( "body" )
				.removeClass( "pt-sm" )
				.attr( "id", "sticky-sub-nav" )

			$( "#sticky-sub-nav" ).append(
				"<div class=\"sticky-sub-nav-trigger\"><span><i></i></span></div>"
			)
			observer.observe( $navSub[0] )

			if ( !TOUCH ) {
				function repositionStickyMenu() {
					let left = $( ".menu-icon" ).get( 0 ).getBoundingClientRect()
						.left
					if ( left ) {
						left = Math.round( left )
						$( ".sticky-sub-nav-trigger" )
							.css( "right", "initial" )
							.css( "left", left + "px" )
					}
				}

				repositionStickyMenu()
				$( window ).on( "resize", repositionStickyMenu )
			}

			// Remove active class from ALL
			let $allRemove = $( ".active.menu-item.menu-all" )
			if ( $allRemove.length && $( "body" ).hasClass( "single-products" ) ) {
				$allRemove.removeClass( "active" )
			}
		}
	}

	/* -------------------------------------------------------------------------- */
	/*                                Loader + AOS                                */
	/* -------------------------------------------------------------------------- */

	let ignore_unload = false

	// Prevent if link has mailto
	$( "a[href^=mailto], a[href^=tel], a.gallery-item" ).on( "click", function () {
		ignore_unload = true
	} )

	$( window ).on( "beforeunload", function ( e ) {
		if ( !ignore_unload ) {
			$( "#loader" ).removeClass( "is-loaded" )
		}
		ignore_unload = false
	} )

	$( window ).on( "load", function () {
		console.log("document loaded")
		
		// Preload lazy
		$( ".__lazy-preload" )
			.removeClass( "__lazy-preload" )
			.addClass( "lazypreload" )
		// Preload video
		// $("video").each(function () {
		// 	let $dis = $(this),
		// 		dis = this,
		// 		$source = $dis.find("source");
		// 	if ($source.length) {
		// 		let datasrc = $source.attr("data-src");
		// 		if (datasrc) {
		// 			$source.attr("src", datasrc).removeAttr("data-src");
		// 			console.log("video preloaded");
		// 		}
		// 	}
		// });
		setTimeout( function () {
			let hash = location.hash
			if ( hash ) {
				hash = hash.replace( "#", "" )
				let scrollToNode = document.getElementById( hash )
				if ( scrollToNode ) {
					window.scrollTo( {
						top: scrollToNode.offsetTop,
						behavior: "auto",
					} )
				}
			}

			// Remove preload-transitions class from body to re-enable transitions
			$( "body" ).removeClass( "preload-transitions" )
			$( "#loader, html" ).addClass( "is-loaded" )
			setTimeout( function () {
				sal( salOptions )
			}, 200 )
		}, 0 )
	} )
} )
